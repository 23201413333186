import React from 'react'
import Layout from '../layouts'
// import { Table } from 'reactstrap';
import Contact from '../components/contact/contact.js'
import BillPostersSlider from '../components/hero/billpostersSlider.js'
class BillPosterPage extends React.Component {
  render() {
    return (
      <Layout head={{ title: 'Customized PSA Campaigns' }}>
        <section id="lunchtruck" className="text-center">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="section-title">
                  Trimex Outdoor Bill Posting Guerrilla Marketing Network
                </h1>
                <div className="text-left">
                  <ul className="list-unstyled checked-list">
                    <li>
                      <strong>Food & Beverage:</strong>&nbsp;Promote your next
                      beverage launch with Bill Posters.
                    </li>
                    <li>
                      <strong>Music, Movies and Events:</strong>&nbsp;Want your
                      new release to gain traction quickly? Promote it with
                      Trimex Bill Posters and generate buzz in the streets
                      before launch.
                    </li>
                    <li>
                      <strong>Fashion:</strong>&nbsp;Promote your next product
                      launch with vivid color posters in trendsetting cities.
                    </li>
                    <li>
                      <strong>Trimex Bill Posting</strong>&nbsp;requires not
                      only creative design, but also persistence and awareness.
                      Our poster locations are the best in the US - offering
                      high visibility in major cities such as NYC, LA, Chicago,
                      Boston, Atlanta, Charlotte, Miami, Orlando, Tampa, Austin,
                      Seattle and many more; ask us for a current proposal and
                      mapped location list in any US Market.
                    </li>
                    <li>
                      <strong>POP Reports</strong>&nbsp;are provided every two
                      weeks. We are the only national guerrilla marketing
                      company in the US that provides 100% POP every two weeks.
                    </li>
                  </ul>
                </div>
                <BillPostersSlider />
              </div>
            </div>
          </div>
        </section>
        <Contact />
      </Layout>
    )
  }
}
export default BillPosterPage
