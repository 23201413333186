import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'

import poster1 from '../../images/network1.png'
import poster2 from '../../images/network2.jpg'
import poster3 from '../../images/network3.jpg'
import poster4 from '../../images/network4.jpg'
import poster5 from '../../images/network5.jpg'
import poster6 from '../../images/network6.jpg'
import poster7 from '../../images/network7.jpg'
import poster8 from '../../images/network8.png'
// import poster9 from '../../images/network9.jpg'

export default class billposterSlider extends Component {
  render() {
    const params = {
      loop: true,
      effect: 'fade',
      pagination: {
        el: '.swiper-pagination',
      },
      clickable: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }

    return (
      <Swiper {...params}>
        <div>
          <img src={poster1} className="img-fluid" alt="mrketingNetwork" />
        </div>
        <div>
          <img src={poster2} className="img-fluid" alt="mrketingNetwork" />
        </div>

        <div>
          <img src={poster3} className="img-fluid" alt="mrketingNetwork" />
        </div>

        <div>
          <img src={poster4} className="img-fluid" alt="mrketingNetwork" />
        </div>
        <div>
          <img src={poster5} className="img-fluid" alt="mrketingNetwork" />
        </div>

        <div>
          <img src={poster6} className="img-fluid" alt="mrketingNetwork" />
        </div>

        <div>
          <img src={poster7} className="img-fluid" alt="mrketingNetwork" />
        </div>
        <div>
          <img src={poster8} className="img-fluid" alt="mrketingNetwork" />
        </div>
        {/*<div>
					<img
						src={poster9}
						className="img-fluid"
						alt="mrketingNetwork"
					/>
				</div>*/}
      </Swiper>
    )
  }
}
